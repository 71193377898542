<template>
  <div>

    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="danger"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <pre class="m-0">{{ error }}</pre>
    </b-alert>

    <b-overlay :show="loading">

      <b-row class="my-4">
        <b-col class="text-right">
          <template v-if="!editing">
            <b-button variant="lightgreen" @click="createNewUser" class="">Create New User</b-button>
          </template>
          <template v-else>
            <b-button variant="danger" @click="cancelEditing" class="">Cancel</b-button>
          </template>
        </b-col>
      </b-row>

      <template v-if="editing">
        <b-form @submit="onSubmit" @reset="onReset" class="py-4 mb-4">
          <h3 class="font-weight-bold mb-4" v-if="!form.id">Create New User</h3>
          <h3 class="font-weight-bold mb-4" v-if="form.id">Edit User</h3>
          <b-row>
            <b-col>
              <b-form-group
                label="Full Name"
              >
                <b-form-input
                  v-model="form.name"
                  type="text"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Email Address"
              >
                <b-form-input
                  v-model="form.email"
                  type="email"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Time Zone"
              >
                <b-form-select
                  v-model="form.timezone"
                  :options="timezones"
                  required
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-row>
                <b-col cols="auto">
                  <b-form-group
                    label="Status"
                  >
                    <b-form-checkbox
                      v-model="form.is_active"
                      :value="1"
                      :unchecked-value="0"
                    >
                      Active
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col cols="auto">
                  <b-form-group
                    label="Permission/Role"
                  >
                    <b-form-checkbox
                      v-model="form.is_admin"
                      :value="1"
                      :unchecked-value="0"
                    >
                      Admin
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group>
                <template #label>
                  <span>Sites</span><br>
                  <b-form-checkbox
                    v-model="allSelected"
                    :indeterminate="indeterminate"
                    aria-describedby="sites"
                    aria-controls="sites"
                    @change="toggleAll"
                  >
                    {{ allSelected ? 'Un-select All' : 'Select All' }}
                  </b-form-checkbox>
                </template>
                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="form.sites"
                    :options="sites"
                    :aria-describedby="ariaDescribedby"
                    name="sites"
                    class="ml-4"
                    aria-label="Individual sites"
                  ></b-form-checkbox-group>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <b-button variant="danger" @click="deleteUser" v-if="form.id">Delete</b-button>
            </b-col>
            <b-col class="text-right">
              <!-- <b-button class="mr-2" @click="cancelEditing">Cancel</b-button> -->
              <b-button type="reset" class="mr-2" variant="danger">Reset</b-button>
              <b-button type="submit" variant="lightgreen" :disabled="!isProfileUpdated">Save</b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-row v-if="form.id">
          <b-col>
            <b-overlay :show="filterLoading" class="py-4">
              <b-row class="mb-1">
                <b-col>
                  <h3 class="font-weight-bold">Alerts & Filters</h3>
                </b-col>
                <b-col class="text-right">
                  <b-button v-if="selectedEmailAlerts.length > 0" variant="danger" class="mr-2" @click="deleteSelectedEmailAlerts()">Delete</b-button>
                  <b-button variant="lightgreen" @click="addNewEmailAlert()">Add</b-button>
                </b-col>
              </b-row>
              <b-table
                :items="userFilters"
                :fields="emailAlertFields"
                show-empty
                selectable
                class="filtersTable"
                @row-selected="onEmailAlertSelected"
              >
                <template #cell(is_selected)="row">
                  <b-form-checkbox v-model="row.rowSelected" @change="v => v ? row.selectRow() : row.unselectRow()">{{ row.item.name }}</b-form-checkbox>
                </template>
                <template #cell(actions)="row">
                  <b-link class="text-lightgreen" @click="startFilterEditing(row.item)"><u>Edit</u></b-link>
                </template>
              </b-table>
            </b-overlay>

          </b-col>
          <b-col>
            <b-form @submit="onSubmitFilter" @reset="onResetFilter" v-if="filterEditing" class="sidebarForm">
              <b-overlay :show="filterLoading" class="formContent">
                <b-button variant="link" class="float-right text-danger" v-if="filterForm.id" @click="removeFilter()"><u>Delete</u></b-button>
                <h3>
                  <span v-if="filterForm.id">Edit </span>
                  <span v-else>New </span>
                  <span>Filter & Email Alert</span>
                </h3>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Filter/Alert Label"
                    >
                      <b-form-input
                        v-model="filterForm.name"
                        type="text"
                        placeholder=""
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <strong class="mr-2">Email Alert Frequency: </strong>
                    <b-form-checkbox v-model="filterForm.meta.is_live" inline>Live Alerts</b-form-checkbox>
                    <b-form-checkbox v-model="filterForm.meta.is_daily" inline>Daily Summary</b-form-checkbox>
                    <b-form-checkbox v-model="filterForm.meta.is_weekly" inline>Weekly Report</b-form-checkbox>
                    <p class="small"><strong>Not receiving alerts?</strong> Search your inbox for subject line "ProspectorIQ SixAxis"</p>
                  </b-col>
                </b-row>
                <template
                  v-for="(cond, index) in filterForm.data"
                >
                  <b-row
                    :key="index"
                    :class="{'mt-4': index == 0, 'mt-2': index > 0}"
                  >
                    <b-col cols="auto">
                      <div class="mt-2">
                        <b-link @click="deleteFilterCondition(index)"><b-icon icon="dash-circle-fill" variant="danger"></b-icon></b-link>
                      </div>
                    </b-col>
                    <b-col cols="4">
                      <b-form-select v-model="cond.key" :options="availableCriteria(cond.key)" @change="cond.value = cond.key == 'industries' ? [] : ''">
                        <template #first>
                          <b-form-select-option :value="''" disabled>-- Please select an option --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-col>
                    <b-col>
                      <template v-if="cond.key == 'industries'">
                        <treeselect
                          v-if="Array.isArray(cond.value)"
                          v-model="cond.value"
                          :multiple="true"
                          :options="industryOptions"
                          :show-count="true"
                          class="filter-industries"
                          placeholder="Select Industries"
                        >
                        </treeselect>
                      </template>
                      <template v-else-if="cond.key != ''">
                        <b-form-input v-if="!Array.isArray(cond.value)" v-model="cond.value"></b-form-input>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row
                    :key="index + '_suffix'"
                    v-if="index != filterForm.data.length - 1"
                    class="mt-2"
                  >
                    <b-col>
                      <strong>AND</strong>
                    </b-col>
                  </b-row>
                </template>
                <b-row class="mt-4">
                  <b-col>
                    <b-link class="text-decoration-none text-dark" @click="addNewFilterCondition()"><b-icon icon="plus-circle" class="mr-2"></b-icon><strong>ADD</strong></b-link>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col class="text-right">
                    <b-button variant="link" class="text-danger mr-4" @click="cancelFilterEditing"><u>Cancel</u></b-button>
                    <b-button type="submit" variant="lightgreen" class="px-5">Save</b-button>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-form>
          </b-col>
        </b-row>
      </template>

      <b-table
        hover
        :items="users"
        :fields="fields"
        small
        v-show="!editing"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(is_active)="row">
          <b-icon
            v-show="row.item.is_active"
            icon="check2"
            variant="success"
          ></b-icon>
        </template>
        <template #cell(last_accessed_at)="row">
          {{ row.item.last_accessed_at && moment(row.item.last_accessed_at).format('MMM DD, Y h:mm A') || '' }}
        </template>
        <template #cell(actions)="row">
          <b-icon icon="pencil-fill" variant="info" @click="startEditing(row.item.id)" style="cursor:pointer;"></b-icon>
        </template>
      </b-table>

    </b-overlay>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Users',
  data() {
    return {
      form: {
        id: 0,
        email: '',
        name: '',
        sites: [],
        timezone: this.moment.tz.guess(),
        is_active: false,
        is_admin: false,
      },
      initialUserData: {
        email: '',
        name: '',
        sites: [],
        timezone: this.moment.tz.guess(),
        is_active: false,
        is_admin: false,
      },
      selectedUser: null,
      editing: false,
      allSelected: false,
      indeterminate: false,
      dismissSecs: 3,
      dismissCountDown: 0,
      fields: [
        {
          key: 'is_active',
          label: 'Active',
          class: 'text-center',
          thClass: 'text-nowrap small',
        },
        {
          key: 'name',
          class: 'text-nowrap',
          thClass: 'text-nowrap small',
          sortable: true,
        },
        {
          key: 'email',
          class: 'text-nowrap',
          thClass: 'text-nowrap small',
          sortable: true,
        },
        {
          key: 'sites',
          formatter: (value) => this.formatter_sites(value),
          // formatter: 'formatter_sites',
          thClass: 'text-nowrap small',
        },
        {
          key: 'last_accessed_at',
          label: 'Last Access',
          thClass: 'text-nowrap small',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      sortBy: 'last_accessed_at',
      sortDesc: true,
      timezones: [
        { value: null, text: 'Please select an option', disabled: true },
        ...this.moment.tz.zonesForCountry('US', true).sort((a, b) => a.offset - b.offset).map(zone => ({ value: zone.name, text: zone.name }))
      ],

      filterEditing: false,
      filterForm: {
        id: null,
        user_id: null,
        name: '',
        type: '',
        meta: {},
        data: [],
      },
      selectedEmailAlerts: [],
      emailAlertFields: [
        {
          key: 'is_selected',
          label: '',
          class: 'text-nowrap',
        },
        {
          key: 'meta',
          label: '',
          formatter: (value) => this.formatter_filterMeta(value),
          class: 'w-100',
        },
        {
          key: 'actions',
          label: '',
        }
      ],
      filterMetas: {
        is_live: 'Live Alerts',
        is_daily: 'Daily Summary',
        is_weekly: 'Weekly Report',
      },
      criteria: [
        { value: 'companyName', text: 'Company' },
        { value: 'city_or_state', text: 'City/State' },
        { value: 'industries', text: 'Industries' },
        { value: 'country', text: 'Country' },
        { value: 'page_keyword', text: 'Page Keyword or URL' },
        { value: 'zipcodes', text: 'Zip' },
      ],
    }
  },
  computed: {
    ...mapGetters('settings/sites', {
      sitesData: 'data',
    }),
    sites: function() {
      return this.sitesData.map(site => ({
        text: site.name,
        value: site.id,
      }))
    },
    ...mapGetters('users', {
      usersData: 'data',
      loading: 'loading',
      error: 'error',
      filterLoading: 'filterLoading',
      userFilters: 'userFilters',
    }),
    users: function() {
      return this.usersData.map(user => ({
        ...user,
        _rowVariant: !user.is_active ? 'secondary' : undefined,
      }))
    },
    ...mapGetters('settings/industries', {
      industry_codes: 'industry_codes',
      industryLoading: 'loading',
    }),
    industryOptions: function() {
      const options = []
      this.industry_codes.forEach(el => {
        const node = {
          id: el.Id,
          label: el.Name,
        }
        const parts = el.Id.split('.')
        if (parts.length == 1) {
          options.push(node)
        } else {
          const parentOption = options.find(option => option.id == parts[0])
          if (parentOption) {
            if (!parentOption.children) parentOption.children = []
            parentOption.children.push(node)
          }
        }
      })
      return options
    },
    isProfileUpdated: function() {
      return this.form.name != this.selectedUser.name
        || this.form.email != this.selectedUser.email
        || this.form.timezone != this.selectedUser.timezone
        || this.form.is_active != this.selectedUser.is_active
        || this.form.is_admin != this.selectedUser.is_admin
        || !Array.equalsIgnoreOrder(this.form.sites, this.selectedUser.sites)
    },
  },
  watch: {
    'form.sites': function(newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.sites.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault()
      try {
        await this.$store.dispatch('users/submit', this.form)
        this.cancelEditing()
      } catch(e) {
        this.showAlert()
      }
    },
    onReset(event) {
      event && event.preventDefault()
      if (this.selectedUser.id) {
        this.startEditing(this.selectedUser.id)
      } else {
        this.form = Object.assign({}, this.form, this.initialUserData)

        // this.form.name = ''
        // this.form.email = ''
        // this.form.timezone = this.moment.tz.guess(true)
        // this.form.is_active = false
        // this.form.is_admin = false
        // this.form.sites = []
        this.editing = false
        this.$nextTick(() => {
          this.editing = true
        })
      }
    },
    toggleAll(checked) {
      this.form.sites = checked ? this.sites.slice().map(site => site.value) : []
    },
    cancelEditing() {
      this.editing = false
    },
    getUserByID(userID) {
      return this.usersData.find(user => user.id == userID)
    },
    createNewUser() {
      this.form.id = 0
      this.selectedUser = Object.assign({}, this.initialUserData)
      this.onReset()
      this.editing = true
    },
    startEditing(user_id) {
      this.selectedUser = Object.assign({}, this.getUserByID(user_id))
      if (typeof this.selectedUser.sites == 'string') {
        this.selectedUser.sites = JSON.parse(this.selectedUser.sites)
      }
      this.form.id = this.selectedUser.id
      this.form.name = this.selectedUser.name
      this.form.email = this.selectedUser.email
      this.form.timezone = this.selectedUser.timezone
      this.form.is_active = this.selectedUser.is_active
      this.form.is_admin = this.selectedUser.is_admin
      this.form.sites = this.selectedUser.sites
      this.editing = true
      this.$store.dispatch('users/loadFilters', {
        user_id: this.selectedUser.id,
      })
    },
    async deleteUser() {
      if (!confirm('Do you really want to delete this user?')) return
      try {
        await this.$store.dispatch('users/delete', this.form)
        this.cancelEditing()
      } catch(e) {
        this.showAlert()
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    formatter_sites(value) {
      let sites = value
      if (typeof value == 'string') {
        sites = JSON.parse(value)
      }
      return sites.map(site_id => this.sitesData.find(site => site.id == site_id)).filter(site => site).map(site => site.name).join(', ')
    },
    formatter_date(value, key, item) {
      return item.last_accessed_at && this.moment(item.last_accessed_at).format('MMM DD, Y h:mm A') || ''
    },

    async onSubmitFilter(event) {
      event.preventDefault()
      try {
        await this.$store.dispatch('users/submitFilter', this.filterForm)
        this.cancelFilterEditing()
      } catch(e) {
        this.showAlert()
      }
    },
    onResetFilter(event) {
      event && event.preventDefault()
      this.filterForm.name = ''
      this.filterForm.type = ''
      this.filterForm.meta = {}
      this.filterForm.data = []
      this.filterEditing = false
      this.$nextTick(() => {
        this.filterEditing = true
      })
    },
    cancelFilterEditing() {
      this.filterEditing = false
    },
    addNewEmailAlert() {
      this.filterForm.id = 0
      this.filterForm.user_id = this.selectedUser.id
      this.onResetFilter()
      this.filterForm.type = 'alert'
      this.filterForm.meta = {
        is_live: false,
        is_daily: false,
        is_weekly: false,
      }
    },
    startFilterEditing(filter) {
      this.filterForm.id = filter.id
      this.filterForm.user_id = this.selectedUser.id
      this.filterForm.name = filter.name
      this.filterForm.type = filter.type
      this.filterForm.meta = filter.meta
      this.filterForm.data = filter.data
      this.filterEditing = true
    },
    formatter_filterMeta(meta) {
      return Object.keys(this.filterMetas).filter(key => meta[key]).map(key => this.filterMetas[key]).join(', ')
    },
    async removeFilter() {
      if (!confirm('Are you sure you want to delete this filter?')) return
      try {
        await this.$store.dispatch('users/deleteFilter', this.filterForm)
        this.cancelFilterEditing()
      } catch(e) {
        this.showAlert()
      }
    },
    onEmailAlertSelected(items) {
      this.selectedEmailAlerts = items
    },
    async deleteSelectedEmailAlerts() {
      if (!confirm('Are you sure you want to delete these email alerts?')) return
      try {
        this.cancelFilterEditing()
        await this.$store.dispatch('users/deleteFilters', this.selectedEmailAlerts.map(filter => filter.id))
      } catch(e) {
        this.showAlert()
      }
    },
    addNewFilterCondition() {
      this.filterForm.data.push({
        key: '',
        value: null,
      })
    },
    deleteFilterCondition(index) {
      this.filterForm.data.splice(index, 1)
    },
    availableCriteria(condKey) {
      return this.criteria.filter(c => !this.filterForm.data.find(cond => cond.key == c.value) || condKey == c.value)
    },
  }

}
</script>