<template>
  <div>
    <b-overlay :show="loading" z-index="9999">
      <b-row>
        <b-col cols="auto">
          <b-form-group
            label="Presets"
          >
            <b-form-radio-group
              v-model="selectedPresetId"
              :options="industryOptionPresets"
              stacked
              @change="onPresetSelected"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-input-group
            v-if="currentEdit"
            prepend="Preset Name"
            class="mb-2"
          >
            <b-form-input
              v-model="currentEdit.label"
              @update="onCurrentPresetUpdated"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="saveCurrentPreset"
                :disabled="!currentEdit.label || !currentEdit.is_updated"
              >
                <template v-if="currentEdit.is_new">
                  Create
                </template>
                <template v-else>
                  Update
                </template>
              </b-button>
              <b-button
                variant="info"
                v-if="currentEdit.is_updated"
                @click="resetCurrentEdit"
              >
                <b-icon icon="arrow-counterclockwise"></b-icon>
              </b-button>
              <b-button
                variant="danger"
                v-if="!currentEdit.is_new"
                @click="removeCurrentPreset"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <treeselect
            v-if="currentEdit"
            v-model="currentEdit.industries"
            :multiple="true"
            :options="industryOptions"
            :show-count="true"
            :always-open="true"
            class="filter-industries"
            placeholder="Select Industries"
            :max-height="640"
            :disabled="!currentEdit"
            @input="onCurrentPresetUpdated"
          >
          </treeselect>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Settings-Industries',
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('settings/industries', {
      industry_codes: 'industry_codes',
      industry_presets: 'industry_presets',
      loading: 'loading',
    }),
    industryOptions: function() {
      const options = []
      this.industry_codes.forEach(el => {
        const node = {
          id: el.Id,
          label: el.Name,
        }
        const parts = el.Id.split('.')
        if (parts.length == 1) {
          options.push(node)
        } else {
          const parentOption = options.find(option => option.id == parts[0])
          if (parentOption) {
            if (!parentOption.children) parentOption.children = []
            parentOption.children.push(node)
          }
        }
      })
      return options
    },
    industryOptionPresets: function() {
      const options = this.industry_presets.map(preset => ({
        value: preset.id,
        text: preset.label,
      }))

      options.push({
        is_new: true,
        value: 'industry_preset_' + new Date().getTime(),
        text: '______Create New Preset______',
      })
      return options
    }
  },
  data() {
    return {
      industries: [],
      presets: [],
      selectedPresetId: null,
      currentEdit: null,
    }
  },
  methods: {
    onPresetSelected() {
      const selectedPreset = this.industry_presets.find(preset => preset.id == this.selectedPresetId) || {
        id: this.selectedPresetId,
        label: '',
        industries: [],
        is_new: true,
      }
      this.currentEdit = Object.assign({}, {
        is_new: false,
        is_updated: false,
        ...selectedPreset,
        original: {
          ...selectedPreset
        },
      })
    },
    saveCurrentPreset() {
      if (this.currentEdit.is_new) {
        this.$store.dispatch('settings/industries/addPreset', {
          ...this.currentEdit,
          is_new: undefined,
          is_updated: undefined,
          original: undefined,
        }).then(() => {
          this.onPresetSelected()
        })
      } else {
        this.$store.dispatch('settings/industries/updatePreset', {
          ...this.currentEdit,
          is_new: undefined,
          is_updated: undefined,
          original: undefined,
        }).then(() => {
          this.onPresetSelected()
        })
      }
    },
    removeCurrentPreset() {
      this.$store.dispatch('settings/industries/removePreset', {
        ...this.currentEdit,
        is_new: undefined,
        is_updated: undefined,
        original: undefined,
      }).then(() => {
        this.selectedPresetId = null
        this.currentEdit = null
      })
    },
    onCurrentPresetUpdated() {
      if (this.currentEdit.label != this.currentEdit.original.label || !Array.equalsIgnoreOrder(this.currentEdit.industries, this.currentEdit.original.industries)) {
        this.currentEdit.is_updated = true
      } else {
        this.currentEdit.is_updated = false
      }
    },
    resetCurrentEdit() {
      this.currentEdit.label = this.currentEdit.original.label
      this.currentEdit.industries = this.currentEdit.original.industries
      this.onCurrentPresetUpdated()
    },
  },
}
</script>