<template>
  <div>
    <b-tabs card vertical pills>
      <b-tab title="Industries">
        <Industries />
      </b-tab>
      <b-tab title="Job Titles">
        <JobTitles />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Industries from '@/components/Settings/Industries.vue'
import JobTitles from '@/components/Settings/JobTitles.vue'

export default {
  name: 'Settings',
  components: {
    Industries,
    JobTitles,
  },
}
</script>