<template>
  <div class="page-manage">
    <b-tabs card>
      <b-tab title="Users">
        <Users />
      </b-tab>
      <b-tab title="Settings">
        <Settings />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Users from '@/components/Users.vue'
import Settings from '@/components/Settings.vue'

export default {
  name: 'Manage',
  components: {
    Users,
    Settings,
  },
}
</script>