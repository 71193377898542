<template>
  <div>
    <b-overlay :show="loading" z-index="9999">
      <div class="h1 mb-0">Job Title Keywords <b-button class="float-right ml-2 px-5" :disabled="!modified" variant="lightgreen" @click="onSave">Save</b-button></div>
      <div class="mb-4"><small class="text-muted">Don't forget to click 'save' before move out.</small></div>
      
      <b-form @submit="onSubmit">
        <b-input-group prepend="Keyword" class="mb-2">
          <b-form-input v-model="keyword" ref="keywordInput"></b-form-input>
          <b-input-group-append>
            <b-button @click="onSubmit" variant="lightgreen">Add</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <b-list-group v-if="jobTitles.length > 0" class="jobTitle-list">
        <b-list-group-item
          v-for="(jobTitle, index) in jobTitles"
          :key="index"
        >
          {{ jobTitle }}
          <b-badge variant="danger" pill class="float-right action-remove" @click="removeJobTitle(index)"><b-icon icon="x"></b-icon></b-badge>
        </b-list-group-item>
      </b-list-group>
      <b-list-group v-else>
        <b-list-group-item>
          No Predefined Job Titles
        </b-list-group-item>
      </b-list-group>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Settings-JobTitles',
  computed: {
    ...mapGetters('settings/jobtitles', {
      jobTitles: 'data',
      loading: 'loading',
    }),
  },
  data() {
    return {
      // loading: false,
      keyword: '',
      // jobTitles: [],
      modified: false,
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault()
      if (this.keyword && !this.jobTitles.includes(this.keyword)) {
        this.jobTitles.push(this.keyword)
        this.keyword = ''
        this.modified = true
      }
      this.$refs.keywordInput.focus()
    },
    removeJobTitle(index) {
      if (!confirm('Do you really want to remove this keyword?')) return
      this.jobTitles.splice(index, 1)
      this.modified = true
    },
    onSave() {
      this.$store.dispatch('settings/jobtitles/save', {
        jobtitles: this.jobTitles,
      })
    }
  },
  beforeUnmount() {
    console.log('fewfew');
    alert('123');
  }
}
</script>